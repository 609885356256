export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'tel',
  },
  {
    key: 'commission',
    label: 'field.commissionPercentage',
    rules: 'required|max_value:100|decimal:2',
    type: 'text',
  },
  {
    key: 'share',
    label: 'field.sharePercentage',
    rules: 'required|max_value:100|decimal:2',
    type: 'text',
  },
  {
    key: 'ccy',
    label: 'field.ccy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'currency',
    selectionKey: 'ccy',
    selectionLabel: 'currency',
  },
  // {
  //   key: 'domainId',
  //   label: 'field.domain',
  //   rules: 'required',
  //   type: 'nAsynSingleSelection',
  //   repository: 'domain',
  //   selectionKey: 'id',
  //   selectionLabel: 'domain',
  // },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isReadonly',
    label: 'status.readonly',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
